import React, { useState, useEffect } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { logPageView } from './utils/analytics';
import axios from 'axios';
import './App.css';

function App() {
  useEffect(() => {
    logPageView(); // Google Analytics page view tracking
  }, []);

  const [asset1, setAsset1] = useState('');
  const [asset2, setAsset2] = useState('');
  const [suggestions1, setSuggestions1] = useState([]);
  const [suggestions2, setSuggestions2] = useState([]);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [isFading, setIsFading] = useState(false); // State for fade-out effect

  const fetchSuggestions = async (query, setSuggestions) => {
    if (!query) {
      setSuggestions([]);
      return;
    }
    try {
      const response = await axios.get(`/api/search/${query}`);
      setSuggestions(response.data || []);
    } catch (error) {
      console.error('Error fetching suggestions:', error.message);
      setSuggestions([]);
    }
  };

  const fetchData = async () => {
    setIsFading(false); // Reset fade-out effect
    setError(null);
    setResult(null);
    if (!asset1 || !asset2) {
      setError('Please enter both asset names.');
      return;
    }
    console.log(`Fetching data for ${asset1} and ${asset2}`);
    try {
      const response1 = await axios.get(`/api/marketcap/${asset1}`);
      const response2 = await axios.get(`/api/marketcap/${asset2}`);
      const newPrice = (response2.data.marketCap / response1.data.marketCap) * response1.data.price;
      setResult({
        newPrice: newPrice.toFixed(2),
        currentPrice: response1.data.price.toFixed(2),
      });
    } catch (error) {
      console.error('Error fetching data:', error.message);
      setError('Error fetching data. Please check the asset names and try again.');
    }
  };

  const handleInputChange = (setter, setSuggestions) => (e) => {
    setter(e.target.value);
    fetchSuggestions(e.target.value, setSuggestions);
    if (result || error) {
      setIsFading(true); // Trigger fade-out effect
      setTimeout(() => {
        setResult(null);
        setError(null);
      }, 500); // Duration of fade-out (matches CSS)
    }
  };

  return (
    <HelmetProvider>
      <>
        <div className="App">
          <Helmet>
            <title>What The Stock - Market Cap Calculator</title>
            <meta name="description" content="What if your favorite stock had NVidia's market cap? Find out now!" />
            <meta name="keywords" content="stock, market, market cap, invest, crypto-currencies" />
          </Helmet>
          <h1>What the Stock</h1>
          <div className="query-container">
            <p>
              What if
              <input
                type="text"
                value={asset1}
                onChange={handleInputChange(setAsset1, setSuggestions1)}
                placeholder="Stock 1"
                list="suggestions1"
              />
              had
              <input
                type="text"
                value={asset2}
                onChange={handleInputChange(setAsset2, setSuggestions2)}
                placeholder="Stock 2"
                list="suggestions2"
              />
              's market cap?
            </p>
            <datalist id="suggestions1">
              {Array.isArray(suggestions1) &&
                suggestions1.map((suggestion) => (
                  <option key={suggestion.symbol} value={suggestion.symbol}>
                    {suggestion.name}
                  </option>
                ))}
            </datalist>
            <datalist id="suggestions2">
              {Array.isArray(suggestions2) &&
                suggestions2.map((suggestion) => (
                  <option key={suggestion.symbol} value={suggestion.symbol}>
                    {suggestion.name}
                  </option>
                ))}
            </datalist>
            <button onClick={fetchData}>Find out</button>
          </div>
          <div className={`result ${isFading ? 'fade-out' : ''}`}>
            {error && <p className="error">{error}</p>}
            {result && (
              <>
                <h2>{asset1} would be ${result.newPrice}</h2>
                <p>{asset1}'s current price is ${result.currentPrice}</p>
              </>
            )}
          </div>
          <footer className="footer">
            <p>Help improve What the Stock with your ideas or contributions:</p>
            <div className="footer-buttons">
              <a
                href="https://forms.gle/your-google-form-link"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-button"
              >
                Submit a feature request
              </a>
              <span> - </span>
              <a
                href="https://paypal.me/whathestock?country.x=FR&locale.x=fr_FR"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-button"
              >
                Support ❤️
              </a>
            </div>
            <p className="footer-text">Made for fun, as an experiment</p>
          </footer>
        </div>
      </>
    </HelmetProvider>
  );
}

export default App;
